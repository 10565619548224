<template>
  <div> </div>
  <!-- <example class="example" />
  <hr />
  <static-dialog class="dialog" title="编辑" :visible="false">
    <div>1234</div>
  </static-dialog> -->
</template>
<script>
//import Example from "@/components/example.vue";
//import StaticDialog from "@/components/static-dialog.vue";
export default {
  name: "default",
  //components: {Example,StaticDialog,},
  data() {
    return {};
  },
  methods: {},
  mounted() { },
  created() { },
};
</script>